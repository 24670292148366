@tailwind base;
@tailwind components;
@tailwind utilities;

.app-footer {
    @apply fixed bottom-0 w-full shadow border-t border-gray-200;
}

.app-tab-bar {
    @apply flex backdrop-filter backdrop-blur bg-white/70;
}

.app-tab-bar > .item {
    @apply text-center flex-1 py-1 text-gray-500;
}

.app-tab-bar > .item.active {
    @apply text-black;
}

.app-tab-bar > .item > .name {
    @apply text-sm;
}

.app-container {
    @apply pb-8;
}

.login-container {
    @apply z-10 py-4 backdrop-filter backdrop-blur bg-gray-200/30 border-y border-t-gray-50 border-b-gray-200;
}

.login-container .form {
    @apply px-4 mx-4;
}

.login-container .form > .form-item {
    @apply mt-2;
}

.login-container .form > .form-item:first-child {
    @apply mt-0;
}

.login-container .form > .form-item > label {
    @apply py-2 block;
}

.login-container .form > .form-item > .form-input {
    @apply p-2 rounded border border-gray-500 pl-8 relative shadow-inner;
}

.login-container .form > .form-item > .form-input > i {
    @apply absolute left-2 text-gray-500;
}

.login-container .form > .form-item > .form-input > input {
    @apply w-full bg-transparent;
}

.login-container .form > .form-item > .desc {
    @apply text-sm text-gray-400;
}

.login-container .form > .form-submit {
    @apply flex mt-10;
}

.login-container .form > .form-submit > .button {
    @apply flex-1 py-2 shadow border border-t-gray-100 border-l-gray-100 border-b-gray-300 border-r-gray-300 rounded-md text-center;
}

.login-container .form > .form-submit > .button.primary {
    @apply bg-gray-800 text-white;
}

.login-container .form > .form-submit > .button.default {
    @apply bg-white;
}

.login-container .form > .form-submit > .button.simple {
    @apply bg-white border-gray-800 flex-auto;
}

@keyframes bg-roll {
    from {
        background-position: 0 0;
    }
    to {
        background-position: 784px -577px;
    }
}

.login-page {
    @apply h-full w-full fixed z-10;
}

.login-page > .login-page-logo {
    @apply bg-gradient-to-br from-gray-700 to-gray-800 shadow-lg rounded-lg w-1/4 m-auto my-10;
}

.login-page-bg {
    @apply fixed z-0;
    top: -300px;
    left: -300px;
    width: 2000px;
    height: 2000px;
    transform: rotate(-20deg);
    opacity: 0.3;
    background-image: url('./bg.jpg');
    background-size: 784px 577px;
    background-repeat: repeat;
    animation: bg-roll 40s linear infinite;
}
