.tabs-container::-webkit-scrollbar {
    display: none;
}
  
.tabs-container {
    @apply w-full overflow-x-auto flex flex-nowrap;
}

.tabs-container > .tab {
    @apply px-4 py-2 flex-shrink-0;
}

.tabs-container > .active {
    @apply border-b-4 border-black;
}
