@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.modal {
  @apply block fixed top-1/3 text-center w-full z-50;
}

.modal > div {
  @apply bg-gray-800 text-white m-auto p-2 w-fit rounded-md z-50;
}

.ReactModal__Body--open,
.ReactModal__Overlay--after-open,
.ReactModal__Overlay--before-close,
.ReactModal__Html--open {
  @apply z-50;
}